import PrimaryButton from '@/components/atoms/Button/PrimaryButton'
import NoRowsOverlay from '@/components/atoms/Icon/NoRowsOverlay'
import { energySavingReportApi } from '@/ghgApi'
import { EnergySavingLevel, EnergySavingOriginateCreate, EnergySavingOriginateCreateArr, EnergySavingReport } from '@/openapi'
import { TEnergySavingTable } from '@/pages/reporting/energy-saving'
import theme from '@/theme'
import { filterByUniqueId } from '@/utils/arrayHelper'
import { convertEnergySavingToCsv } from '@/utils/csvHelper'
import { sumOfMatrix } from '@/utils/math'
import { checkMessageText } from '@/utils/messageHelper'
import useStore from '@/zustand/sotre'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles } from '@material-ui/core'
import React, { useMemo, useState, SetStateAction, useEffect } from 'react'
import EnergySavingTableRow from "./TableRow/EnergySavingTableRow";

interface EnergySavingTable {
    data: TEnergySavingTable
    setCsvData: React.Dispatch<SetStateAction<Blob | undefined>>
    selectedYear: number
    onReFetch: () => void
}
interface EnergySavingLevelWithUnit extends EnergySavingLevel {
    units?: Unit[]
}
export interface EnergySavingRow {
    siteId: number | undefined
    title: string | undefined
    items: EnergySavingRowItem[]
}
interface EnergySavingRowItem {
    energySavingId?: number | undefined
    valueType?: ValueType | undefined
    value: number
    unit?: string | undefined
    inputtable: boolean
}
type ValueType =
    | 'activityQuantity'
    | 'emissionNumber'
    | 'heatQuantity'
    | 'total'

const useStyle = makeStyles({
    btnSaveData: {
        position: 'fixed',
        backgroundColor: theme.colors.white,
        textAlign: 'end',
        right: 22,
        zIndex: 1,
        width: '100%',
        paddingBottom: 16,
    },
    container: {
        padding: 10,
        border: `1px solid ${theme.colors.border}`,
        position: 'relative',
        '& .vertical-line': {
            position: 'absolute',
            left: 0,
            top: 0,
            backgroundColor: '#fff',
            width: 160,
            height: 'calc(100% - 58px)',
        },
        '& .desc': {
            marginTop: 10,
            textAlign: 'right',
            fontWeight: 300,
        },
    },
    tableContainer: {},
    stickyCol: {
        position: 'sticky',
        left: 1,
    },
    cell: { minWidth: 100, fontSize: 14, padding: 9 },
    headerCell: {
        fontWeight: 600,
        color: '#fff',
        backgroundColor: '#6E6E6E',
        borderRight: '3px solid #fff',
        borderBottom: '3px solid #fff',
    },
    unitCell: { backgroundColor: '#AAAAAA' },
    bodyCell: { color: '#222222', fontWeight: 300 },
    bodySiteCell: {
        backgroundColor: '#FDF3DF',
        borderRight: '3px solid #fff',
        borderBottom: '3px solid #fff',
    },
    bodyTotalCell: { fontWeight: 700, backgroundColor: '#F5DBAE' },
    inputCol: {
        padding: '8px 0px 8px 16px',
    },
    input: {
        width: 135,
        marginLeft: 7,
        '& fieldset': {
            borderColor: theme.colors.lightGray2,
            borderRadius: 7,
        },
        '& input': {
            borderColor: theme.colors.lightGray2,
            borderRadius: 7,
            textAlign: 'right',
        },
    },
})
type Unit = { name: string[]; id?: number }
const genUnits = (isCsvDownload?: boolean, isFullSize?: boolean, id?: number, unit: string = 'kl'): Unit[] => {
    const result = [
        { name: ['①活動量', `（${unit}）`], id },
        { name: ['②排出量', '（t-CO₂e）'], id },
        { name: ['③熱量', '（GJ）'], id },
    ]
    if (isFullSize) {
        result.splice(1, 0, { name: ['①活動量', '（kwh）'], id })
        result.splice(1, 0, { name: ['①活動量', '（1000Nm3）'], id })
        result.splice(1, 0, { name: ['①活動量', '（t）'], id })
    }
    if (isCsvDownload) {
        result.push({ name: ['④係数'], id })
    }
    return result
}

function EnergySavingTable({ data: { headers, filteredData: data }, ...props }: EnergySavingTable) {
    const classes = useStyle()
    const {
        setMessage,
        storeState: { sites },
    } = useStore()
    const [originalRows, setOriginalRows] = useState<EnergySavingRow[]>([])
    const [rows, setRows] = useState<EnergySavingRow[]>([])
    const [tableCol, setTableCol] = useState<Unit[]>([])
    const [tableColCSV, setTableColCSV] = useState<Unit[]>([])
    const [disabledSaveButton, setDisabledSaveButton] = useState(true)

    useEffect(() => {
        const rows = initializeTableData()
        setOriginalRows(rows)
        setRows(rows)
        setDisabledSaveButton(true)
    }, [data])

    function getLastLevelChildren(obj: EnergySavingLevel) {
        let lastLevelChildren: EnergySavingLevel[] = []
        if (obj.children) {
            obj.children.forEach((child) => {
                if (child.children) {
                    lastLevelChildren = lastLevelChildren.concat(child.children)
                } else {
                    lastLevelChildren.push(child)
                }
            })
        }
        return lastLevelChildren
    }

    const tableHeader = useMemo(() => {
        const colUnitsDownload: Unit[][] = []
        const newHeaders = headers.map((h) => {
            const children = getLastLevelChildren(h)
            const colUnits = children
                .map((child) => {
                    const result = genUnits(false, false, child.id, child.gj_unit)
                    const resultForDownload = genUnits(true, false, child.id, child.gj_unit)
                    colUnitsDownload.push(resultForDownload)
                    return result
                })
                .flat()
            setTableCol((pre) => pre.concat(colUnits))
            setTableColCSV(colUnitsDownload.flat())
            return { ...h, units: colUnits }
        })
        return [
            { name: '拠点名' },
            { name: '合計', units: genUnits(false, true) },
            ...newHeaders,
        ] as EnergySavingLevelWithUnit[]
    }, [headers])

    const initializeTableData = (isCsvDownload: boolean = false): EnergySavingRow[] => {
        const result: EnergySavingRow[] = data.map((site: EnergySavingReport) => {
            const values: EnergySavingRowItem[][] = []
            const totalUnits: { [key: string]: number } = {
                kl: 0,
                kwh: 0,
                Nm3: 0,
                t: 0,
                tCO2: 0,
                gJ: 0,
            }
            const uniqueTableCol = filterByUniqueId(tableCol)
            uniqueTableCol.forEach((col, index) => {
                const valuesEg: EnergySavingRowItem[] = [
                    { value: 0, inputtable: false },
                    { value: 0, inputtable: false },
                    { value: 0, inputtable: false }
                ]
                if (isCsvDownload) {
                    valuesEg.push({ value: 0, inputtable: false })
                }
                values.push(valuesEg)
                site.data?.forEach((data) => {
                    if (!data) {
                        return
                    }
                    if (data.energy_saving_id === col.id) {
                        if (data.activity || data.inputtable) {
                            values[index][0] = {
                                energySavingId: data.energy_saving_id,
                                valueType: 'activityQuantity',
                                value: data.activity ?? 0,
                                unit: data.unit,
                                inputtable: data.inputtable ?? false,
                            }
                            switch (data.unit) {
                                case 'kl': {
                                    totalUnits.kl = totalUnits.kl + (data.activity ?? 0)
                                    break
                                }
                                case 't': {
                                    totalUnits.t = totalUnits.t + (data.activity ?? 0)
                                    break
                                }
                                case '1000Nm3': {
                                    totalUnits.Nm3 = totalUnits.Nm3 + (data.activity ?? 0)
                                    break
                                }

                                case 'kwh': {
                                    totalUnits.kwh = totalUnits.kwh + (data.activity ?? 0)
                                    break
                                }
                            }
                        }
                        if (data.emission_number || data.inputtable) {
                            values[index][1] = {
                                energySavingId: data.energy_saving_id,
                                valueType: 'emissionNumber',
                                value: data.emission_number ?? 0,
                                unit: 't-CO2e',
                                inputtable: data.inputtable ?? false,
                            }
                            totalUnits.tCO2 = totalUnits.tCO2 + (data.emission_number ?? 0)
                        }
                        if (data.amount_heat || data.inputtable) {
                            values[index][2] = {
                                energySavingId: data.energy_saving_id,
                                valueType: 'heatQuantity',
                                value: data.amount_heat ?? 0,
                                unit: 'gj',
                                inputtable: data.inputtable ?? false,
                            }
                            totalUnits.gJ = totalUnits.gJ + (data.amount_heat ?? 0)
                        }
                        if (isCsvDownload && data.coefficient) {
                            values[index][3] = {
                                energySavingId: data.energy_saving_id,
                                value: data.coefficient,
                                inputtable: data.inputtable ?? false,
                            }
                        }
                    }
                })
            })
            const items: EnergySavingRowItem[] = [
                { value: totalUnits.kl, inputtable: false },
                { value: totalUnits.t, inputtable: false },
                { value: totalUnits.Nm3, inputtable: false },
                { value: totalUnits.kwh, inputtable: false },
                { value: totalUnits.tCO2, inputtable: false },
                { value: totalUnits.gJ, inputtable: false },
                ...values.flat(),
            ]
            return {
                siteId: site.site_id,
                title: site.site_name,
                items: items,
            }
        })
        return result
    }

    const updateTotalRow = (rows: EnergySavingRow[]) => {
        const matrixData = rows.map((r) => r.items.map((item) => item.value))
        const totalRowItemValues = sumOfMatrix(matrixData)
        // 合計行のsiteIdは、便宜上`-1`とする
        const allRows = [
            ...rows.filter((row) => row.siteId !== -1),
            { siteId: -1, title: '合計', items: totalRowItemValues.map((value) => { return { value: value, inputtable: false } }) }
        ]
        return allRows
    }

    const updateTotaUnits = (items: EnergySavingRowItem[]) => {
        const totalUnits: { [key: string]: number } = {
            kl: 0,
            kwh: 0,
            Nm3: 0,
            t: 0,
            tCO2: 0,
            gJ: 0,
        }
        const uniqueTableCols = filterByUniqueId(tableCol)
        items.forEach((item) => {
            if (!item.energySavingId || !item.unit) {
                return
            }
            uniqueTableCols.forEach((col, index) => {
                if (item.energySavingId != col.id) {
                    return
                }
                switch (item.unit) {
                    case 'kl': {
                        totalUnits.kl = totalUnits.kl + item.value
                        break
                    }
                    case 't': {
                        totalUnits.t = totalUnits.t + item.value
                        break
                    }
                    case '1000Nm3': {
                        totalUnits.Nm3 = totalUnits.Nm3 + item.value
                        break
                    }
                    case 'kwh': {
                        totalUnits.kwh = totalUnits.kwh + item.value
                        break
                    }
                    case 't-CO2e': {
                        totalUnits.tCO2 = totalUnits.tCO2 + item.value
                        break
                    }
                    case 'gj': {
                        totalUnits.gJ = totalUnits.gJ + item.value
                        break
                    }
                }
            })
        })
        return [
            { value: totalUnits.kl, inputtable: false },
            { value: totalUnits.t, inputtable: false },
            { value: totalUnits.Nm3, inputtable: false },
            { value: totalUnits.kwh, inputtable: false },
            { value: totalUnits.tCO2, inputtable: false },
            { value: totalUnits.gJ, inputtable: false },
            ...items.flat().filter((_, index) => index >= 6),
        ]
    }

    const tableData = useMemo(() => {6
        return updateTotalRow(rows)
    }, [sites, rows, tableCol])

    useEffect(() => {
        // FIXME: to be improved
        const { grandParent, parent, children } = require('../../../../dummy/energy_saving.json')
        const subHeader = genUnits(false, true)
        const csvResult = initializeTableData(true).map((td) => { return { name: td.title, data: td.items.map((item) => item.value) } })
        const matrixData = csvResult.map((r) => r.data)
        const units = subHeader.concat(tableColCSV).map((head) => {
            let unit = head.name.toString().replace(',', '')
            if (unit.includes('t-CO₂e')) {
                unit = unit.replace('t-CO₂e', 't-CO2e')
            }
            return unit
        })
        const data = csvResult.map((re) => [re.name, ...re.data])
        const total = sumOfMatrix(matrixData)
        const csvRowData = [grandParent, parent, children, ['-', ...units], ...data, ['合計', ...total]]
        const csvData = convertEnergySavingToCsv(csvRowData)
        props.setCsvData(csvData)
    }, [tableData])

    const handleClickSaveButton = async () => {
        if (!rows.length) {
            return
        }
        if (changedNothing(rows)) {
            return
        }

        const tmpRows = [...rows].filter((row) => row.siteId != -1)
        const arrs: EnergySavingOriginateCreateArr[][] = tmpRows.map((row) => {
            const itemMap = row.items.filter((item) => !!item.energySavingId).reduce((map, item) => {
                const key = `${row.siteId}_${item.energySavingId}`
                const value: EnergySavingOriginateCreateArr = map.get(key) ?? {
                    energy_saving_id: item.energySavingId!!,
                    site_id: row.siteId!!,
                    quantity: 0,
                    number_emission: 0,
                    heat_quantity: 0,
                }
                if (item.valueType === 'activityQuantity') {
                    value.quantity = item.value
                }
                if (item.valueType === 'emissionNumber') {
                    value.number_emission = item.value
                }
                if (item.valueType === 'heatQuantity') {
                    value.heat_quantity = item.value
                }
                map.set(key, value)
                return map
            }, new Map<string, EnergySavingOriginateCreateArr>())
            const results: EnergySavingOriginateCreateArr[] = []
            itemMap.forEach((value, _) => { results.push(value) })
            return results
        })
        const flatedArrs = [...arrs.flat()]
        const params: EnergySavingOriginateCreate = {
            year: props.selectedYear,
            data: flatedArrs,
        }
        try {
            await energySavingReportApi.createEnergySavingOriginate(params)
            props.onReFetch()
            setMessage({ message: '入力値を保存しました。', type: 'success' })
        } catch (error: any) {
            console.error({ error })
            const message = checkMessageText(error?.status, error?.data?.errors?.[0]?.message)
            setMessage({ message, type: 'error' })
        }
    }

    const handleChangeRow = (row: EnergySavingRow) => {
        const index = rows?.findIndex((r) => r.siteId === row.siteId)
        if (!rows[index]) {
            return
        }
        const tmpItems = updateTotaUnits(row.items)
        const tmpRow = {
            ...row,
            items: tmpItems,
        }
        const tmpRows = [...rows]
        tmpRows[index] = tmpRow
        setRows(tmpRows)
        
        const disabled = changedNothing(tmpRows)
        setDisabledSaveButton(disabled)
    }

    const changedNothing = (targetRows: EnergySavingRow[]) => {
        return originalRows.every((or, rowIndex) => {
            return JSON.stringify(or.items) === JSON.stringify(targetRows[rowIndex].items)
        })
    }

    return (
        <>
            <div className={classes.btnSaveData}>
                <div>
                    <PrimaryButton
                        style={{ fontWeight: 400 }}
                        width={215}
                        height={38}
                        fontSize={15}
                        disabled={disabledSaveButton}
                        onClick={handleClickSaveButton}
                    >
                        入力したデータを保存する
                    </PrimaryButton>
                </div>
            </div>
            <div className={classes.container}>
                <div className="vertical-line" />
                <TableContainer className={classes.tableContainer}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {tableHeader.map((item, idx) => {
                                    return (
                                        <TableCell
                                            key={idx}
                                            style={{ minWidth: 150 }}
                                            colSpan={item?.units?.length || 1}
                                            rowSpan={idx === 0 ? 4 : idx === 1 ? 3 : 1}
                                            className={`${classes.cell} ${classes.headerCell} ${
                                                !idx ? classes.stickyCol : ''
                                            }`}
                                            align="center"
                                        >
                                            {item.name}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                            <TableRow>
                                {tableHeader.map((item) => {
                                    if (!item?.children?.length) return null
                                    return item?.children?.map((inner, idx) => {
                                        return (
                                            <TableCell
                                                key={idx}
                                                colSpan={inner?.children ? inner?.children?.length * 3 : 3}
                                                rowSpan={inner?.children?.length ? 1 : 2}
                                                className={`${classes.cell} ${classes.headerCell}`}
                                                align="center"
                                            >
                                                {inner.name}
                                            </TableCell>
                                        )
                                    })
                                })}
                            </TableRow>
                            <TableRow>
                                {tableHeader.map((item) => {
                                    return item?.children?.map((inner) => {
                                        return inner?.children?.map((inner2, idx) => {
                                            return (
                                                <TableCell
                                                    key={idx}
                                                    colSpan={inner2.children ? inner2.children?.length * 3 : 3}
                                                    className={`${classes.cell} ${classes.headerCell}`}
                                                    align="center"
                                                >
                                                    {inner2.name}
                                                </TableCell>
                                            )
                                        })
                                    })
                                })}
                            </TableRow>
                            <TableRow>
                                {tableHeader.map((h) => {
                                    if (!h?.units) return
                                    return h?.units?.map((unit, idx) => {
                                        return (
                                            <TableCell
                                                key={idx}
                                                className={`${classes.cell} ${classes.headerCell} ${classes.unitCell}`}
                                                align="center"
                                            >
                                                {unit.name.map((name, idx) => (
                                                    <span
                                                        key={idx}
                                                        style={{ fontSize: idx ? 12 : 14, display: 'inline-block' }}
                                                    >
                                                        {name}
                                                    </span>
                                                ))}
                                            </TableCell>
                                        )
                                    })
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData.length > 1 ? (
                                tableData.map((site, idx) => {
                                    return (
                                        <EnergySavingTableRow
                                            key={site.siteId}
                                            row={site}
                                            onChangeRow={handleChangeRow}
                                        />
                                    )
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={tableCol.length + 7} align="center" className={``}>
                                        <NoRowsOverlay />
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className="desc">※各項目の数値は端数処理をしているため、小計と合計が一致しない場合があります。</div>
            </div>
        </>
    )
}

export default EnergySavingTable
